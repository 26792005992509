import { useTitle } from '@os/hooks';
import { Box, Button, Typography } from '@os/ui';
import React from 'react';
import { useTranslation } from 'react-i18next';

const lang = 'session-expired';

function SessionExpired() {
  const { t, ready: translationReady } = useTranslation(['translation', lang]);
  useTitle(t(`${lang}:title`));

  const handleActionClick = () => {
    window.location = '/';
  };

  if (!translationReady) {
    return null;
  }

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        flexWrap="nowrap"
        alignContent="center"
        justifyContent="flex-start"
        alignItems="center"
      >
        <Typography variant="h1" mb={2} pt={4} fontSize="32px" fontWeight={900}>
          {t(`${lang}:title`)}
        </Typography>
        <Typography my={1} px={2}>
          {t(`${lang}:information`)}
        </Typography>
        <Button buttonType="primary" my={2} onClick={handleActionClick}>
          {t(`${lang}:action`)}
        </Button>
      </Box>
    </>
  );
}

export default SessionExpired;
